/** @format */

#bg {
    .bg-wrap {
        position: relative;
        display: inline-block;
        margin-top: 20px;
        @media screen and (max-width: 600px) {
            margin-top: 10px;
        }

        @media screen and (max-width: 390px) {
            margin-top: 0px;
        }
    }

    .bg-wrap .inn {
        color: #fff;
        display: inline-block;
        font-size: 52px;
        text-shadow: 2px 2px black;
        font-weight: bold;
        padding: 8px 15px;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 900px) {
            font-size: 42px;
        }
        @media screen and (max-width: 600px) {
            font-size: 32px;
        }

        @media screen and (max-width: 390px) {
            font-size: 20px;
        }
    }
}

@keyframes bg {
    0% {
        opacity: 0;
        transform: scaleX(0) translateX(-5%);
    }
    30% {
        transform: scaleX(1) translateX(0);
    }
    100% {
        transform: scaleX(1) translateX(0);
    }
    30%,
    100% {
        opacity: 1;
    }
}

/* スクロールCSS */
.fade-in {
    opacity: 0;
    transition-duration: 500ms;
    transition-property: opacity, transform;
}

.fade-in-up {
    transform: translate(0, 50px);
}

.fade-in-down {
    transform: translate(0, -50px);
}

.fade-in-left {
    transform: translate(-50px, 0);
}

.fade-in-right {
    transform: translate(50px, 0);
}

.scroll-in {
    opacity: 1;
    transform: translate(0, 0);
}
