/** @format */

body {
    font-size: 62.5%;
    font-family: "Montserrat", sans-serif;
    font-family: "Zen Kaku Gothic New", sans-serif;
    color: #003c86;
    word-wrap: break-word;
}

header {
    position: sticky;
    top: 0;
    z-index: 100;

    .header-nav {
        width: 100%;
        height: 100%;
        font-size: 1.2rem;
        display: flex;
        justify-content: space-between;
        background-color: #043c86;

        @media screen and (max-width: 425px) {
            height: 40px;
        }

        & > img {
            width: 200px;

            @media screen and (max-width: 425px) {
                width: 100px;
            }
        }

        .nav-contact {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            color: #ffffff;
            background-color: #f18301;
            text-decoration: none;
            transition: 0.5s;

            @media screen and (max-width: 390px) {
                width: 100px;
                font-size: 1rem;
            }
        }
    }
}

.main {
    font-size: 1.2rem;

    @media screen and (max-width: 600px) {
        font-size: 1rem;
        overflow: hidden;
    }

    @media screen and (max-width: 390px) {
        font-size: 0.95rem;
    }

    img {
        border-radius: 20px;
    }

    // コンテンツのタイトル
    .contents-width {
        max-width: 930px;
        margin: 200px auto;

        @media screen and (max-width: 600px) {
            margin: 50px auto;
            padding-bottom: 60px;
        }
    }

    .contents-bg {
        padding: 100px 0;
        background-color: #e4eefc;
    }

    .contents-title {
        margin-bottom: 200px;
        text-align: center;

        @media screen and (max-width: 600px) {
            margin: 0 25px 100px;
        }

        .head {
            color: #043c86;
            margin-bottom: 10px;
        }

        .sub {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                background-color: #043c86;
                top: 40px;
                left: 50%;
                width: 1px;
                height: 50px;
                border: #043c86;
            }
        }
    }

    .contents-detail {
        line-height: 1.7rem;
        margin: 0 auto;

        @media screen and (max-width: 600px) {
            margin: 0 25px;
            padding: 0;
        }
    }
    //ここまで

    .top {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: rgba(0, 0, 0, 0.2);
            @media screen and (max-width: 600px) {
                display: none;
            }
        }

        .top-bg {
            width: 100%;
            height: 100vh;
            object-fit: cover;
            border-radius: 0;

            @media screen and (max-width: 600px) {
                height: auto;
            }
        }

        .top-title {
            position: absolute;
            top: 65%;
            left: 10%;
            transform: translate(0, -50%);
            z-index: 1;

            @media screen and (max-width: 600px) {
                top: 70%;
                left: 0%;
                right: 0;
                width: 100%;
            }

            @media screen and (max-width: 390px) {
                top: 70%;
                left: 52%;
                right: 0;
                width: 100%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .concept {
        .concept-name {
            margin-top: 40px;
            padding-right: 200px;
            text-align: right;
            font-size: 2.4rem;
            font-family: "Yuji Mai", serif;

            @media screen and (max-width: 600px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 390px) {
                font-size: 1.4rem;
                padding: 0;
            }
        }
    }

    .service {
        background-color: #e4eefc;

        .service-detail {
            max-width: 930px;

            .service-item {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 50px;
                margin: 150px 0;
                color: #043c86;

                @media screen and (max-width: 390px) {
                    display: block;
                    margin: 0 auto 50px;
                    text-align: center;
                }

                & > img {
                    opacity: 0.8;
                    width: 350px;
                    height: 250px;
                    object-fit: cover;
                    border-radius: 25px;
                    background: #ffffff;
                    box-shadow: 30px 30px 60px #d9d9d9, -30px -30px 60px #ffffff;

                    @media screen and (max-width: 600px) {
                        height: auto;
                        width: 50%;
                    }

                    @media screen and (max-width: 390px) {
                        width: 100%;
                        height: 150px;
                        border-radius: 5px;
                    }
                }

                .text {
                    width: 50%;

                    @media screen and (max-width: 390px) {
                        width: 90%;
                        margin: auto;
                    }

                    .text-title {
                        text-align: center;
                        font-size: 1.8rem;
                        font-weight: bold;
                        padding-bottom: 30px;

                        @media screen and (max-width: 600px) {
                            font-size: 1.2rem;
                        }
                    }

                    .text-detail {
                        font-size: 1.2rem;

                        @media screen and (max-width: 600px) {
                            font-size: 0.95rem;
                        }
                    }
                }
            }
        }
    }

    .floor-img {
        & > img {
            opacity: 0.8;
            border-radius: 0;
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }

    .floor {
        .floor-item {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;
            margin: 150px 0;

            @media screen and (max-width: 390px) {
                display: block;
                text-align: center;
            }

            &:nth-child(even) {
                flex-direction: row-reverse;
            }

            & > img {
                opacity: 0.8;
                width: 350px;
                height: 250px;
                object-fit: cover;
                border-radius: 25px;
                background: #ffffff;
                box-shadow: 30px 30px 60px #d9d9d9, -30px -30px 60px #ffffff;

                @media screen and (max-width: 600px) {
                    height: auto;
                    width: 50%;
                }

                @media screen and (max-width: 390px) {
                    width: 100%;
                    height: 150px;
                    border-radius: 5px;
                }
            }

            .text {
                width: 50%;

                @media screen and (max-width: 390px) {
                    width: 90%;
                    margin: auto;
                }
                .text-title {
                    text-align: center;
                    font-size: 2rem;
                    font-weight: bold;
                    padding-bottom: 30px;

                    @media screen and (max-width: 600px) {
                        font-size: 1.2rem;
                    }
                }

                .text-detail {
                    font-size: 1.2rem;

                    @media screen and (max-width: 600px) {
                        font-size: 0.95rem;
                    }
                }
            }
        }
    }

    .strong {
        margin-bottom: 200px;

        .strong-detail {
            position: relative;
            display: block;
            margin: 0 auto;
            padding: 0;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            & > video {
                opacity: 0.2;
                width: 100%;
                object-fit: cover;
                max-height: 500px;

                @media screen and (max-width: 600px) {
                    height: 350px;
                }
            }

            .strong-point {
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
                list-style: none;
                z-index: 1;
                width: 65%;

                @media screen and (max-width: 600px) {
                    width: 75%;
                    top: 40%;
                }

                .point-item {
                    position: relative;
                    padding: 40px 20px;
                    border-top: solid 1px #043c86;

                    @media screen and (max-width: 600px) {
                        padding: 0 5px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                    }
                    &:last-child {
                        border-bottom: solid 1px #043c86;
                    }

                    .num {
                        position: absolute;
                        top: 50%;
                        left: -10%;
                        transform: translate(-50%, -50%);
                        width: 42px;
                        height: 42px;
                        padding: 8px;
                        font-size: 1.6rem;
                        border-radius: 50%;
                        text-align: center;
                        color: #fef955;
                        background-color: #043c86;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media screen and (max-width: 600px) {
                            width: 20px;
                            height: 20px;
                            font-size: 1rem;
                        }
                    }

                    .detail {
                        color: #043c86;
                        font-size: 1.6rem;
                        font-weight: bold;

                        @media screen and (max-width: 600px) {
                            font-size: 1rem;
                            line-height: normal;
                        }

                        @media screen and (max-width: 390px) {
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }

    .staff {
        .contents-staff {
            display: flex;
            align-items: center;
            text-align: center;
            gap: 50px;

            @media screen and (max-width: 600px) {
                display: block;
            }

            & > img {
                width: 30%;

                @media screen and (max-width: 600px) {
                    width: 70%;
                    height: auto;
                }
            }

            .img-detail {
                min-width: 50%;
                text-align: center;

                .title {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    margin-bottom: 50px;
                    font-size: 2rem;
                    font-weight: bold;

                    @media screen and (max-width: 600px) {
                        font-size: 1.5rem;
                    }

                    @media screen and (max-width: 390px) {
                        display: block;
                        padding: 0;
                    }

                    & > li {
                        width: 30%;

                        @media screen and (max-width: 390px) {
                            width: 100%;
                        }

                        &:last-child {
                            width: 70%;

                            @media screen and (max-width: 390px) {
                                width: 100%;
                            }
                        }

                        & > span {
                            word-break: keep-all;
                            margin-left: 50px;
                            line-height: 1rem;
                            font-size: 1.2rem;

                            @media screen and (max-width: 390px) {
                                margin: 0 auto;
                                padding: 0;
                            }
                        }
                    }
                }

                .detail {
                    text-align: left;
                    word-break: keep-all;
                }
            }
        }
    }

    .howUse {
        .howUse-detail {
            max-width: 930px;
            margin: auto;
            text-align: center;

            > img {
                display: block;
                margin: 20px auto;
            }

            > a {
                display: block;
            }
        }
    }

    .price {
        .detail-scroll {
            overflow: scroll;
        }
        .price-table {
            margin: auto;
            line-height: 1.6rem;
            border-collapse: collapse;

            thead {
                text-align: center;

                th {
                    border-right: solid 1px black;
                    width: 170px;
                    padding: 0 25px 10px;
                    position: relative;
                    white-space: nowrap;
                    &:last-child {
                        border-right: none;
                    }

                    & > span {
                        position: absolute;
                        bottom: 5px;
                        right: 15px;
                        font-size: 0.8rem;
                    }
                }
            }

            tr {
                td {
                    border-right: solid 1px black;
                    padding: 20px 0;
                    text-align: center;

                    &:first-child {
                        text-align: left;
                        width: 250px;
                        padding: 0 25px;
                    }

                    &:last-child {
                        border-right: none;
                    }
                }
            }

            .table-item {
                &:nth-child(6) {
                    border-bottom: black solid 1px;
                }

                &:nth-child(odd) {
                    background-color: #e4eefc;
                }

                &:last-child {
                    background-color: #ffffff;
                }
            }
        }

        .price-announce {
            margin: 20px 0 0 20px;
            font-size: 0.8rem;
        }
    }

    .access {
        .access-table {
            margin: auto;
            line-height: 1.6rem;
            border-collapse: collapse;

            .table-item {
                @media screen and (max-width: 390px) {
                    display: flex;
                    flex-direction: column;
                }

                td {
                    border-bottom: solid 1px black;
                    padding: 20px 50px;

                    @media screen and (max-width: 390px) {
                        border-bottom: none;
                        padding: 5px 50px;

                        &:first-child {
                            background-color: #e4eefc;
                        }
                    }

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }

        .access-iframe {
            margin: 100px auto 0;
            width: 100%;
            text-align: center;
        }
    }

    .contact {
        .contact-detail {
            margin: auto;
            max-width: 920px;
        }
        /* Contact Form7 */
        .contact7 {
            padding: 0;
            max-width: 1024px;
            width: 100%;
            box-sizing: border-box;

            @media screen and (max-width: 600px) {
                width: 80%;
                margin: 0 auto;
            }

            .form-layout {
                list-style: none;
                text-align: center;

                & input,
                textarea {
                    border: none;
                    width: 95%;
                    margin-bottom: 25px;
                    padding: 10px;

                    @media screen and (max-width: 600px) {
                        width: 90%;
                    }
                }

                .form-title {
                    text-align: left;
                }

                /* 必須マーク */
                .must {
                    background: #ff1a00;
                    font-size: 1.2rem;
                }

                /* 任意マーク */
                .optional {
                    background: #999;
                }

                .must,
                .optional {
                    color: #fff;
                    border-radius: 3px;
                    font-size: 10px;
                    margin-left: 10px;
                    padding: 5px 10px;
                    letter-spacing: 2px;
                }
            }
        }
        .btn_contact7 {
            text-align: center;
            input {
                width: 100%;
                background-color: #f18301;
                color: #fff;
                font-size: 1.1em;
                font-weight: bold;
                letter-spacing: 0.2em;
                transition: 0.3s;
                padding: 10px 0;
                border: none;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
        @media screen and (min-width: 768px) {
            .btn_contact7 {
                display: block;
                width: 100%;

                input {
                    background-color: #f18301;
                    color: #fff;
                    font-size: 1.1em;
                    font-weight: bold;
                    letter-spacing: 0.2em;
                    transition: 0.3s;
                    padding: 10px 0;
                }
            }
        }
    }
}
footer {
    .back-color {
        background-color: #003c86;
        padding: 100px;

        .nav {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            width: 420px;
            margin: auto;

            @media screen and (max-width: 600px) {
                width: 100%;
                padding: 0;
            }

            .nav-list {
                & > a {
                    font-size: 1.2rem;
                    color: #e4eefc;
                    text-decoration: none;
                }
            }
        }
    }
}

.side-icon {
    opacity: 0;
    transition: 0.7s;

    &.active {
        opacity: 0.7;
    }

    .sns-icon {
        list-style: none;
        position: fixed;
        top: 30%;
        right: 5%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 50px;
        z-index: 9999;

        .item {
            i {
                color: black;
                font-size: 2rem;
            }
        }
    }
}

.counselor-icon {
    opacity: 0;
    position: fixed;
    bottom: 5%;
    left: 5%;
    transition: 0.7s;
    z-index: 9999;

    @media screen and (max-width: 390px) {
        width: 190px;
    }

    &.active {
        opacity: 1;
    }

    .main-title {
        background-color: #f18301;
        padding: 15px 20px;
        border-radius: 30px;

        @media screen and (max-width: 390px) {
            padding: 10px 15px;
            text-align: center;
        }

        & > a {
            font-size: 1.2rem;
            font-weight: bold;
            color: #e4eefc;
            text-decoration: none;

            @media screen and (max-width: 390px) {
                font-size: 12px;
            }
        }
    }
}

.sp-only {
    display: none;

    @media screen and (max-width: 390px) {
        display: block;
    }
}